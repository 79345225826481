import React from "react"
import ArrowIconMinimal from "../Icons/ArrowIconMinimal"

export interface SelectOption {
    displayText: string
    value: string | number
}

export interface InputSelectProps {
  label?: string
  id?: string
  name?: string
  options: Array<SelectOption>
  defaultFilterOption?: SelectOption
  defaultFilterValue?: string | number | null
  className?: string
  onInputSelectChange: (selected: SelectOption, target: EventTarget) => void
}

const InputSelect = ({ label, id, name, options, defaultFilterOption, defaultFilterValue, className, onInputSelectChange }: InputSelectProps) => {
  let value: string | number = ''
  if(defaultFilterOption?.value != null) {
    value = defaultFilterOption?.value
  }
  else if(defaultFilterValue) {
    value = defaultFilterValue
  }
  else if (options[0].value != null) {
    value = options[0].value
  }

  const onDropDownChange = (e: React.FormEvent<HTMLSelectElement>) => {
    const value = e.currentTarget.value
    const newSelectedOption = options.find((o) => o.value === value)
    if(newSelectedOption !== undefined) {
      onInputSelectChange(newSelectedOption, e.currentTarget)
    } else {
      throw new Error("InputSelect: Selected value not found in given options array")
    }
  }

  return (
    <div className={`relative${className ? ' ' + className : ''}`}>
      {label && 
        <label htmlFor='inputSelect' className='inline-block mb-1'>{label}</label>
      }
      <select
        id={id}
        name={name}
        onChange={onDropDownChange}
        className='select-filter appearance-none cursor-pointer outline-hidden'
        value={value}
      >
        {options.map((option) =>
          <option key={option.value} value={option.value}>
            {option.displayText}
          </option>
        )}
      </select>
      <div className='select-icon flex items-center top-0 right-0 mr-3 pointer-events-none'>
        <ArrowIconMinimal direction='down' color='var(--color-dark-gray)' size='15px' />
      </div>
    </div>
  )
}

export default InputSelect