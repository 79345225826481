import React, { useEffect, useRef, useState } from 'react'
import './GroceryFeed.scss'
import useSignalRFeed from '../../hooks/useSignalrFeed'
import StoreGroceryStock from '../../types/StoreGroceryStock'
import config from '../../config'
import { WebSocketInvocation } from '../../hooks/webSocketInvocation'
import SpinnerIcon from '../Icons/SpinnerIcon'
import { filterFeed, FilterOption, getFilterOptions, sortAlphabetically, sortAsc } from './filterAndSortFeedItems'
import GroceryFeedItem from '../GroceryFeedItem/GroceryFeedItem'
import AnimatedList from '../AnimatedList/AnimatedList'
import Modal from '../Modal/Modal'
import { remove } from '../../utils/http'
import InputSelect, { SelectOption } from '../InputSelect/InputSelect'

export default function GroceryFeed() {
  const invocations: Array<WebSocketInvocation<any>> = [{
    name: 'UpsertStoreGroceryStock',
    action: (updatedFeedItem: StoreGroceryStock) => {
      setFeed((prevFeedState: Array<StoreGroceryStock>) => {
        return [
          ...prevFeedState.filter((item) => item.ean !== updatedFeedItem.ean),
          ...[updatedFeedItem]]
      })
    }
  }, {
    name: 'UpsertStoreGroceryStocks',
    action: (updatedFeedItems: Array<StoreGroceryStock>) => {
      setFeed((prevFeedState: Array<StoreGroceryStock>) => {
        return [
          ...prevFeedState.filter((item) => !updatedFeedItems.some(i => i.ean === item.ean)),
          ...updatedFeedItems]
      })
    }
  }]

  const { initialFeed, loading, error } = useSignalRFeed<StoreGroceryStock>(config.GROCERY_STOCK_FEED_SIGNALR_URL, `${config.GROCERY_STOCK_API_BASE_URL}/v1/grocerystock`, invocations)

  const [httpError, setHttpError] = useState(false)
  const [httpLoading, setHttpLoading] = useState(false)

  const [feed, setFeed] = useState<Array<StoreGroceryStock>>([])
  const [filteredFeed, setFilteredFeed] = useState<Array<StoreGroceryStock>>([])

  const [filterOptions, setFilterOptions] = useState<Array<FilterOption>>([])
  const [selectedFilterOption, setSelectedFilterOption] = useState(filterOptions[0])

  const [activeSort, setActiveSort] = useState<'asc' | 'alphabetically'>('asc')
  const [activeFilter, setActiveFilter] = useState<'all' | 'available' | 'almostOut' | 'out'>('all')
  const listItemRef = useRef<any>({})

  const [removingGrocery, setRemovingGrocery] = useState<boolean>(false)
  const [selectedGrocery, setSelectedGrocery] = useState<StoreGroceryStock>()

  // Removal
  const startRemoving = (grocery: StoreGroceryStock) => {
    setSelectedGrocery(grocery)
    setRemovingGrocery(true)
  }

  const stopRemoving = () => {
    setRemovingGrocery(false)
    setSelectedGrocery(undefined)
  }

  const removeGrocery = async (grocery: StoreGroceryStock | undefined) => {
    if (grocery === undefined) {
      return
    }

    setHttpLoading(true)
    setHttpError(false)
    try {
      await remove<any>(`${config.GROCERY_STOCK_API_BASE_URL}/v1/grocerystock/${grocery.storeId}/${grocery.ean}`)
      setRemovingGrocery(false)
      setFeed([...feed.filter(item => item.ean !== grocery.ean)])
      setFilterOptions(getFilterOptions(feed))
    } catch (error) {
      setHttpError(true)
    }
    setHttpLoading(false)
  }

  // Filtering
  const changeFilter = (e: React.FormEvent<HTMLButtonElement>) => {
    const value = e.currentTarget.value
    const newFilterOption = filterOptions.find((o) => o.value === value)
    if(newFilterOption !== undefined) {
      setActiveFilter(newFilterOption.value)
      setSelectedFilterOption(newFilterOption)
      if(activeSort === 'asc') {
        setFilteredFeed(sortAsc(filterFeed(feed, newFilterOption.value)))
      } else {
        setFilteredFeed(sortAlphabetically(filterFeed(feed, newFilterOption.value)))
      }
    } else {
      throw new Error("Filter selection: Selected value not found in given options array")
    }
  }

  // Sorting
  const onDropDownChange = (selected: SelectOption) => {
    const value = selected.value
    if(value === 'asc') {
      setActiveSort(value)
      setFilteredFeed(sortAsc(filteredFeed))
    } else if(value === 'alphabetically') {
      setActiveSort(value)
      setFilteredFeed(sortAlphabetically(filteredFeed))
    }
  }

  // Effects
  useEffect(() => {
    setFeed(sortAsc(initialFeed))
    setFilterOptions(getFilterOptions(initialFeed))
  }, [initialFeed])

  useEffect(() => {
    if(activeSort === 'asc') {
      setFilteredFeed(sortAsc(filterFeed(feed, selectedFilterOption?.value)))
    } else {
      setFilteredFeed(sortAlphabetically(filterFeed(feed, selectedFilterOption?.value)))
    }
    setFilterOptions(getFilterOptions(feed))
  }, [feed])

  return (
    <>
      {!error && !loading && feed.length === 0 &&
      <div className='error grid place-content-center'>
        Det finns inget att se här ännu.
      </div>}
      {loading && !error && <div className='feed-spinner'><SpinnerIcon /> </div>}
      {feed.length > 0 &&
      <div className='grocery-feed__filter'>
        <div className='grocery-feed__filter-left mobile-scroll-horizontal flex items-center'>
          <p className='self-center select-none my-2 mr-2 ml-0 md:ml-2'>
            Visa:
          </p>
          <div className='flex'>
            {filterOptions.map((option) =>
              <div key={option.value} className='grocery-feed__quantity-filter-button'>
                <button id={option.value} value={option.value} className={`button-radio${activeFilter === option.value ? '-active' : ''}`} onClick={changeFilter}>{option.displayText}</button>
              </div>
            )}
          </div>
        </div>
        <div className='grocery-feed__filter-right flex order-last items-center md:w-fit sm:w-fit my-4'>
          <p className='select-none my-2 mr-2'>
            Sortera:
          </p>
          <InputSelect
            id="inputSelect"
            name="inputSelect"
            className='grow'
            onInputSelectChange={onDropDownChange}
            options={[
              { value: 'asc', displayText: 'Lågt antal' },
              { value: 'alphabetically', displayText: 'Namn A-Ö' }
            ]}
            defaultFilterValue={activeSort}/>
        </div>
      </div>}
      <div className='grid lg:grid-cols-3 md:grid-cols-2 md:auto-cols'>
        <AnimatedList>
          {filteredFeed.map((item) =>
            <GroceryFeedItem
              key={item.ean}
              item={item}
              startRemoving={startRemoving}
              ref={listItemRef.current[item.ean as keyof { current: any }] ??= { current: null }} />
          )}
        </AnimatedList>
      </div>
      {!loading && error && <p className='error'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
      {!httpLoading && httpError && <p className='error'>Någonting gick fel. Försök igen genom att ladda om sidan.</p>}
      <Modal title='Är du säker?' show={removingGrocery} onClose={stopRemoving}>
        <div className='flex items-center mt-4'>
          <button className='button grow mr-1' onClick={() => removeGrocery(selectedGrocery)}>Ja</button>
          <button className='button grow ml-1' onClick={stopRemoving}>Nej</button>
        </div>
      </Modal>
    </>
  )
}
